/* eslint-disable @typescript-eslint/no-unused-vars */

export const APP_VERSION: string = 'v1.0.12'; //#changeme
/*
version notes:
1.0.0=initial
1.0.9=changing state to ABBREV only in PDF export
1.0.10=add quote checkbox, showTermsFooter
*/

export const USE_PL_CACHE: boolean = false; //#changeme in prod use [true]

export const DEBUG_LOG_DIDMOUNT: boolean = false; //#changeme in prod use [false]

export const ANT_MESSAGE_AUTOCLOSE_DELAY: number = 5;

export const LOOKUP_TAKE_COUNT: number = 30; // for lookupdatapicker exclusively

export const REGEX_CURRENCY = /^\s*$|^[$]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;

export const Messages = class {
  public static ErrorCheckConsole: string = 'Error occurred, check Browser Console (Developer Toolbar) for more info.';
}

export const CONTAINER_QUOTES: string = 'Quotes';
export const CONTAINER_PRICELISTITEMS: string = 'PriceListItems';

export const GET_BR_CONTACTS = (): string => {
  return (window as any)['runtimeConfig']['brContacts'] || '';
}

export const GET_STATES = (): any[] => {
  return (window as any)['runtimeConfig']['states'] || [];
}

export const GET_CONTRACT_TERMS = (): string[] => {
  return (window as any)['runtimeConfig']['contractTerms'] || [];
}

export const GET_TERMS_FOOTER = (): string => {
  return (window as any)['runtimeConfig']['termsFooter'] || '';
}

export class ENUM_STATUS {
  public static DRAFT: string = "DRAFT";
  public static SUBMITTED: string = "SUBMITTED";
  public static CLOSED: string = "CLOSED";
  public static REJECTED: string = "REJECTED";
  public static DEAD: string = "DEAD";
}
