/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';

import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';
import {
  BRClient,
  BRQuote,
  ClientPrincipal,
  createSalesItemObject,
  PriceListItemCollection,
  SalesItem,
} from '../../Library/Models';
import WebApiHelper from '../../Library/WebApiHelper';
import WrappedDrawer from '../Helpers/WrappedDrawer';
import { WrappedTable } from '../Helpers/WrappedTable';
import { CustomItemDetail } from './CustomItemDetail';
import { SalesItemDetail } from './SalesItemDetail';

const { Title } = Typography;
const { Option } = Select;

const _required1: boolean = true; // #changeme must always be true
const _required2: boolean = true; // #changeme should be true for PROD, but using false for TESTING


export interface IQuoteDetailProps {
  curUser: ClientPrincipal;
  priceListItemsCol: PriceListItemCollection;
  curQuote: BRQuote;
  quotes: BRQuote[];
  handleSubmitDrawer: (data: BRQuote, isNew: boolean) => void;
  handleCancelDrawer: () => void;
}

export const QuoteDetail: React.FunctionComponent<IQuoteDetailProps> = (props: React.PropsWithChildren<IQuoteDetailProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[QuoteDetail] mounted!');
  }, []);


  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showSalesItemDrawer, setShowSalesItemDrawer] = useState(false);
  const [showCustomItemDrawer, setShowCustomItemDrawer] = useState(false);
  const [curSalesItem, setCurSalesItem] = useState<SalesItem>(createSalesItemObject());
  const [salesItems, setSalesItems] = useState<SalesItem[]>([...props.curQuote.salesItems]);
  const [quoteIsAnnual, setQuoteIsAnnual] = useState(props.curQuote.isAnnual);
  /*
    Annual checkbox/switch notes:
    -I can lock the component once its "checked", to prevent changes and math errors/recalcs needed
    -Or I can wipe the saleItems array when its switched
    -I don't have to reset the entire salesItems array when this control is switched, BUT each item will likely have to be openned and edited when switched
    -In SalesItemDetail, the USER is entering the annual sales price when this is checked
    -the brCost and msrp remain unchanged, so we need to use the multiplier in QuoteSummary and QuoteDetail too (only when showing msrp and brCost)
    -so if we toggle annual on/off the multiplier can be reliably used in salesitemdetail
    -but, the total cost then changes within the salesitem object, which impacts its calculated totals (margin, profit)
    -so if the annual switch changes, the USER will need to open each sales item and change the sellprice, and then the code recalcs the cost and totals
    -this works OK
  */


  function handleIsAnnualChange(checked: boolean) {
    AppHelper.showMessageWarningBar(`When changing Annual checkbox make sure you update your sales items and prices!`);
    setQuoteIsAnnual(checked);
    //setSalesItems([]);
  }


  function handleOnFinish(formValues: any) {
    if (loading) return;

    setLoading(true);
    setTimeout(() => {

      (async () => {
        // gather form values
        let subject = GenUtil.safeTrim(formValues.subject);
        let brContactName = GenUtil.safeTrim(formValues.brContactName);
        let terms = GenUtil.safeTrim(formValues.terms);
        let quoteDate = formValues.quoteDate.startOf('day').toDate();
        let quoteExpires = formValues.quoteExpires.startOf('day').toDate();
        let busName1 = GenUtil.safeTrim(formValues.busName1);
        let busName2 = GenUtil.safeTrim(formValues.busName2);
        let street1 = GenUtil.safeTrim(formValues.street1);
        let street2 = GenUtil.safeTrim(formValues.street2);
        let city = GenUtil.safeTrim(formValues.city);
        let usState = GenUtil.safeTrim(formValues.usState);
        let zip = GenUtil.safeTrim(formValues.zip);
        let phone = GenUtil.safeTrim(formValues.phone);
        let email = GenUtil.safeTrim(formValues.email);
        let contactName1 = GenUtil.safeTrim(formValues.contactName1);
        let contactName2 = GenUtil.safeTrim(formValues.contactName2);

        let taxRate = GenUtil.safeToNumber(formValues.taxRate);
        let taxLabel = GenUtil.safeTrim(formValues.taxLabel);
        if (taxRate === 0) taxLabel = '';

        let isAnnual = GenUtil.safeToBool(formValues.isAnnual);
        let showTermsFooter = GenUtil.safeToBool(formValues.showTermsFooter);

        let closeDate = null;
        if (typeof formValues.closeDate !== 'undefined' && formValues.closeDate != null)
          closeDate = formValues.closeDate.startOf('day').toDate();
        else closeDate = null;

        let renewalDate = null;
        if (typeof formValues.renewalDate !== 'undefined' && formValues.renewalDate != null)
          renewalDate = formValues.renewalDate.startOf('day').toDate();
        else renewalDate = null;

        // extra validation
        if (taxRate > 0 && taxLabel === '') {
          AppHelper.showMessageWarningBar(`Tax Label is required if Tax Rate is entered.`);
          setLoading(false);
          return;
        }

        if (salesItems.length <= 0) {
          AppHelper.showMessageWarningBar(`Please add at least one Sales Item.`);
          setLoading(false);
          return;
        }
        // create payload
        let pl: BRQuote = { ...props.curQuote };

        if (typeof pl.status === 'undefined' || GenUtil.isNull(pl.status)) pl.status = Consts.ENUM_STATUS.DRAFT;

        pl.brContactName = brContactName;
        pl.quoteDate = quoteDate;
        pl.quoteExpires = quoteExpires;
        pl.terms = terms;
        pl.subject = subject;

        pl.isAnnual = isAnnual;
        pl.showTermsFooter = showTermsFooter;

        pl.closeDate = closeDate;
        pl.renewalDate = renewalDate;

        pl.taxRate = taxRate;
        pl.taxLabel = taxLabel;

        pl.brClient.busName1 = busName1;
        pl.brClient.busName2 = busName2;
        pl.brClient.street1 = street1;
        pl.brClient.street2 = street2;
        pl.brClient.city = city;
        pl.brClient.usState = usState;
        pl.brClient.zip = zip;
        pl.brClient.phone = phone;
        pl.brClient.email = email;
        pl.brClient.contactName1 = contactName1;
        pl.brClient.contactName2 = contactName2;

        // gather salesitems, totals
        pl.salesItems = [...salesItems];

        let { tot_qty, tot_tc, tot_ts, tot_pr, tot_ma } = AppHelper.calcSalesItemsTotals(salesItems);

        pl.totalCost = tot_tc;
        pl.totalMargin = tot_ma;
        pl.totalSale = tot_ts;
        pl.totalProfit = tot_pr;
        pl.totalQuantity = tot_qty;


        // submit to web svc
        if (pl.quoteNumber === '0000') {
          // create new quote

          pl.createdBy = props.curUser.userDetails;
          pl.modifiedBy = props.curUser.userDetails;

          // build quoteNumber from cosmosdb query
          let countResp = await WebApiHelper.get1(`/api/web-svc?action=${'COSMOSDBQUERY'}&c=${encodeURIComponent(Consts.CONTAINER_QUOTES)}&q=${encodeURIComponent(`SELECT COUNT(1) as counter FROM c WHERE c.curYear=${(new Date()).getFullYear()}`)}`);

          if (countResp.isErr) {
            AppHelper.showMessageErrorBar('ERROR: ' + countResp.msg);
            setLoading(false);
            return;
          }

          let counter: number = countResp.data[0].counter + 1; // increment by 1

          // update quote number in object
          pl.quoteNumber = `${new Date().getFullYear()}-${GenUtil.padLeadingZeros(counter + '', 4)}`;

          // save new quote in cosmosdb
          let saveResp = await WebApiHelper.post1(`/api/web-svc?action=COSMOSDBINSERT&c=${Consts.CONTAINER_QUOTES}`, pl);

          if (saveResp.isErr) {
            AppHelper.showMessageErrorBar('ERROR: ' + saveResp.msg);
            setLoading(false);
            return;
          }
          else {

            setLoading(false);
            AppHelper.showMessageSuccessBar('Saved!');
            props.handleSubmitDrawer(saveResp.data, true);
            return;
          }

        }
        else {
          // update existing quote

          pl.modifiedBy = props.curUser.userDetails;
          pl.modifiedDatetime = new Date();

          // update quote in cosmosdb
          let updateResp = await WebApiHelper.post1(`/api/web-svc?action=COSMOSDBUPDATE&c=${Consts.CONTAINER_QUOTES}`, pl);

          if (updateResp.isErr) {
            AppHelper.showMessageErrorBar('ERROR: ' + updateResp.msg);
            setLoading(false);
            return;
          }
          else {
            setLoading(false);
            AppHelper.showMessageSuccessBar('Saved!');
            props.handleSubmitDrawer(updateResp.data, false);
            return;
          }
        }

      })();
    }, 100);
  }


  function renderBRContacts() {
    return GenUtil.convertStrToList(Consts.GET_BR_CONTACTS(), ',').map((o, i) => {
      return (
        <Option key={i} value={o}>{o}</Option>
      );
    });
  }

  function renderUSStates() {
    return Consts.GET_STATES().map((o, i) => {
      return (
        <Option key={i} value={o.name}>{`${o.name} - ${o.abbrev}`}</Option>
      );
    });
  }

  function renderContractTerms() {
    return Consts.GET_CONTRACT_TERMS().map((o, i) => {
      return (
        <Option key={i} value={o}>{`${o}`}</Option>
      );
    });
  }

  function renderSavedClients() {

    let clients = props.quotes.map((o, i) => {
      return o.brClient;
    });

    let col1: BRClient[] = [];

    clients.forEach((o) => {
      if (col1.findIndex((o2) => {
        return o2.busName1 === o.busName1 && o2.contactName1 === o.contactName1;
      }) < 0) {
        col1.push(o);
      }
    });

    col1 = _.sortBy(col1, ['busName1', 'contactName1']);

    return col1.map((o, i) => {
      return (
        <Option key={i} value={`${o.busName1}|${o.contactName1}`}>{`${o.busName1} - ${o.contactName1}`}</Option>
      );
    });
  }

  function handleSavedClientChange(val: string) {

    let parts = GenUtil.convertStrToList(val, '|');

    if (parts.length !== 2) { return; }

    let busName = parts[0];
    let contactName = parts[1];

    let idx = props.quotes.findIndex((o) => {
      return o.brClient.busName1 === busName && o.brClient.contactName1 === contactName;
    });

    if (idx < 0) { return; }

    let savedClient = { ...props.quotes[idx].brClient };

    form.setFieldsValue(savedClient);
  }

  function handleAddSalesItem() {
    setCurSalesItem(createSalesItemObject());
    setShowSalesItemDrawer(true);
  }

  function handleAddCustomItem() {
    setCurSalesItem(createSalesItemObject());
    setShowCustomItemDrawer(true);
  }

  function handleCancelSalesItemDrawer() {
    setShowSalesItemDrawer(false);
  }

  function handleCancelCustomItemDrawer() {
    setShowCustomItemDrawer(false);
  }

  function handleSubmitSalesItemDrawer(data: SalesItem, isNew: boolean) {
    console.log('submit data', data);
    // salesitem was created or updated
    if (isNew) {
      // add quote to collection
      setSalesItems((p) => {
        let t = [...p, { ...data }];
        t = _.sortBy(t, ['description']);
        return t;
      });
    }
    else {
      // update quote in collection
      setSalesItems((p) => {
        let t = p.filter(x => x.itemId !== data.itemId);
        t = [...t, data];
        t = _.sortBy(t, ['description']);
        return t;
      })
    }

    setShowSalesItemDrawer(false);
  }

  function handleSubmitCustomItemDrawer(data: SalesItem, isNew: boolean) {
    console.log('submit data', data);
    // salesitem was created or updated
    if (isNew) {
      // add quote to collection
      setSalesItems((p) => {
        let t = [...p, { ...data }];
        t = _.sortBy(t, ['description']);
        return t;
      });
    }
    else {
      // update quote in collection
      setSalesItems((p) => {
        let t = p.filter(x => x.itemId !== data.itemId);
        t = [...t, data];
        t = _.sortBy(t, ['description']);
        return t;
      })
    }

    setShowCustomItemDrawer(false);
  }

  function handleSalesItemDelete(o: SalesItem) {
    // remove the selected sales item from the collection
    setSalesItems((p) => {
      return p.filter(x => x.itemId !== o.itemId);
    });
  }

  function updateSalesItemsWithTotals() {

    let col = [...salesItems];

    if (col.length <= 0) {
      return [];
    }

    let { tot_qty, tot_tc, tot_ts, tot_pr, tot_ma } = AppHelper.calcSalesItemsTotals(col);

    col = [...col, {
      brCost: -999,
      description: 'Totals',
      id: -998,
      itemId: '-998',
      licAgrType: '',
      margin: tot_ma,
      msrp: -999,
      productId: '',
      profit: tot_pr,
      quantity: tot_qty,
      sellPrice: -999,
      source: '',
      totalCost: tot_tc,
      totalSale: tot_ts,
      billingPlan: '',
      termDuration: ''
    }];

    if (!quoteIsAnnual) {
      col = [...col, {
        brCost: -999,
        description: 'Annualized',
        id: -999,
        itemId: '-999',
        licAgrType: '',
        margin: tot_ma,
        msrp: -999,
        productId: '',
        profit: tot_pr * 12,
        quantity: tot_qty,
        sellPrice: -999,
        source: '',
        totalCost: tot_tc * 12,
        totalSale: tot_ts * 12,
        billingPlan: '',
        termDuration: ''
      }];
    }

    return col;
  }


  const salesItemsCols: any[] = [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 35,
      render: (t: string, o: SalesItem) => {
        if (o.itemId.startsWith('-9'))
          return '';
        else
          return (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                handleSalesItemDelete(o);
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className='grid-icon' />
            </Popconfirm>);
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (t: string, o: SalesItem) => (
        o.itemId.startsWith('-9') ?
          '' :
          <Button type='link' className='clr' disabled={loading} onClick={() => {
            setCurSalesItem(o);
            if (o.id > 0) {
              setShowSalesItemDrawer(true);
            }
            else {
              setShowCustomItemDrawer(true);
            }
          }}>{o.description}</Button>
      )
    },
    {
      title: 'Billed',
      dataIndex: 'billingPlan',
      key: 'billingPlan'
    },
    {
      title: 'Term',
      dataIndex: 'termDuration',
      key: 'termDuration'
    },
    {
      title: 'MSRP',
      dataIndex: 'msrp',
      align: 'right',
      key: 'msrp',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? '' : GenUtil.numberToCurrency(o.msrp * (quoteIsAnnual ? 12 : 1), true)
      )
    },
    {
      title: 'B&R Cost',
      dataIndex: 'brCost',
      align: 'right',
      key: 'brCost',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? '' : GenUtil.numberToCurrency(o.brCost * (quoteIsAnnual ? 12 : 1), true)
      )
    },
    {
      title: 'Sell Price',
      dataIndex: 'sellPrice',
      align: 'right',
      key: 'sellPrice',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='quote_grid_totals'>{o.description}:</span> :
          GenUtil.numberToCurrency(o.sellPrice, true)
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
      key: 'quantity',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{o.quantity}</span>
          : <span>{new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(o.quantity)}</span>
      )
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      align: 'right',
      key: 'totalCost',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.totalCost, true)}</span>
          : <span>{GenUtil.numberToCurrency(o.totalCost, true)}</span>
      )
    },
    {
      title: 'Total Sale',
      dataIndex: 'totalSale',
      align: 'right',
      key: 'totalSale',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.totalSale, true)}</span>
          : <span>{GenUtil.numberToCurrency(o.totalSale, true)}</span>
      )
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      align: 'right',
      key: 'profit',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.profit, true)}</span>
          : <span>{GenUtil.numberToCurrency(o.profit, true)}</span>
      )
    },
    {
      title: 'Margin',
      dataIndex: 'margin',
      key: 'margin',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.margin, false) + '%'}</span>
          : <span>{GenUtil.numberToCurrency(o.margin, false) + '%'}</span>
      )
    }
  ];


  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  return (
    <>

      <div className='wbs25'>

        <Form
          form={form}
          style={{ width: '90%' }}
          {...formLayout}
          layout="horizontal"
          onFinish={handleOnFinish}
        >

          <Title level={4} className='wbs25'>
            Quote Summary:
            <Tag style={{ marginLeft: 8 }} color={AppHelper.getStatusTagColor(props.curQuote.status)}>{props.curQuote.status}</Tag>
          </Title>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Subject"
                name="subject"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.subject}
                rules={[{ required: _required1, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="B&amp;R Contact Name"
                name="brContactName"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brContactName}
                rules={[{ required: _required2, message: 'Please select a value' }]}
              >
                <Select
                  placeholder="Please select a value"
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {renderBRContacts()}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Contract Terms"
                name="terms"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.terms}
                rules={[{ required: _required2, message: 'Please select a value' }]}
              >
                <Select
                  placeholder="Please select a value"
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {renderContractTerms()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Quote Date"
                name="quoteDate"
                rules={[{ required: _required2, message: 'Please select a value' }]}
                initialValue={props.curQuote.quoteNumber === '0000' ? moment(new Date()) : moment(props.curQuote.quoteDate)}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Expires Date"
                name="quoteExpires"
                rules={[{ required: _required2, message: 'Please select a value' }]}
                initialValue={props.curQuote.quoteNumber === '0000' ? moment().endOf('month') : moment(props.curQuote.quoteExpires)}
              >
                <DatePicker format="MM/DD/YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Show Terms Footer"
                name="showTermsFooter"
                initialValue={props.curQuote.quoteNumber === '0000' ? true : props.curQuote.showTermsFooter}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          {
            props.curQuote.status === Consts.ENUM_STATUS.CLOSED && (

              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Close Date"
                    name="closeDate"
                    rules={[{ required: true, message: 'Please select a value' }]}
                    initialValue={props.curQuote.quoteNumber === '0000' ? moment(new Date()) : props.curQuote.closeDate != null ? moment(props.curQuote.closeDate) : undefined}
                  >
                    <DatePicker format="MM/DD/YYYY" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Renewal Date"
                    name="renewalDate"
                    initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.renewalDate != null ? moment(props.curQuote.renewalDate) : undefined}
                  >
                    <DatePicker format="MM/DD/YYYY" />
                  </Form.Item>
                </Col>
              </Row>

            )
          }

          <Divider />

          <Title level={4} className='wbs25'>Client Information:</Title>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Saved Clients"
                name="savedClient"
              >
                <Select
                  placeholder="Choose a client to load its information"
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={handleSavedClientChange}
                >
                  {renderSavedClients()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>

            </Col>
          </Row>


          <Row>
            <Col span={12}>
              <Form.Item
                label="Business/Org. Name"
                name="busName1"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.busName1}
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Business/Org. Name Extra"
                name="busName2"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.busName2}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Street Address 1"
                name="street1"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.street1}
                rules={[{ required: _required2, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Street Address 2"
                name="street2"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.street2}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.city}
                rules={[{ required: _required2, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="usState"
                initialValue={props.curQuote.quoteNumber === '0000' ? 'New Jersey' : props.curQuote.brClient.usState}
                rules={[{ required: _required2, message: 'Please select a value' }]}
              >
                <Select
                  placeholder="Please select a value"
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {renderUSStates()}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Zip"
                name="zip"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.zip}
                rules={[{ required: _required2, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>

            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Primary Phone"
                name="phone"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.phone}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Primary Email"
                name="email"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.email}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Primary Contact Name"
                name="contactName1"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.contactName1}
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Secondary Contact Name"
                name="contactName2"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.brClient.contactName2}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Title level={4} className='wbs25'>Tax Information:</Title>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Tax Rate"
                name="taxRate"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.taxRate}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tax Label"
                name="taxLabel"
                initialValue={props.curQuote.quoteNumber === '0000' ? undefined : props.curQuote.taxLabel}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <div className='wbs25'>
            <Space align={'baseline'}>
              <div style={{ fontSize: '20px', fontWeight: 600, paddingRight: '12px' }}>Sales Items:</div>
              <Button size={'small'} type='primary' onClick={handleAddSalesItem} disabled={loading} icon={<PlusOutlined />}>Add Sales Item</Button>
              <Button size={'small'} type='primary' onClick={handleAddCustomItem} disabled={loading} icon={<PlusOutlined />}>Add Custom Item</Button>
            </Space>
          </div>


          <div className='wbs25'>
            <WrappedTable
              columns={salesItemsCols}
              dataSource={updateSalesItemsWithTotals()}
              loading={loading}
              rowKey={'itemId'}
              skipRowSelection={true}
              useClientOnlyPager={false}
              size='small'
              pageSize={5000}
            ></WrappedTable>
          </div>

          <Divider />

          <div className='wbs25'>
            <Space>
              <Button type="default" htmlType="button" icon={<CloseOutlined />} onClick={props.handleCancelDrawer} disabled={loading}>Cancel</Button>
              <Button type="primary" htmlType="submit" disabled={loading} icon={<SaveOutlined />} onClick={() => {
                form.submit();
              }}>Save</Button>
            </Space>
          </div>
        </Form>

        <WrappedDrawer
          closable={true}
          handleCancelDrawer={handleCancelSalesItemDrawer}
          showDrawer={showSalesItemDrawer}
          title={curSalesItem.itemId === '' ? 'Add Sales Item' : `Edit Sales Item`}
          width='70%'>

          <SalesItemDetail
            curSalesItem={curSalesItem}
            curUser={props.curUser}
            isAnnual={quoteIsAnnual}
            priceListItemsCol={props.priceListItemsCol}
            handleCancelDrawer={handleCancelSalesItemDrawer}
            handleSubmitDrawer={handleSubmitSalesItemDrawer}
          ></SalesItemDetail>

        </WrappedDrawer>

        <WrappedDrawer
          closable={true}
          handleCancelDrawer={handleCancelCustomItemDrawer}
          showDrawer={showCustomItemDrawer}
          title={'Add Custom Item'}
          width='70%'>

          <CustomItemDetail
            curSalesItem={curSalesItem}
            curUser={props.curUser}
            isAnnual={quoteIsAnnual}
            priceListItemsCol={props.priceListItemsCol}
            handleCancelDrawer={handleCancelCustomItemDrawer}
            handleSubmitDrawer={handleSubmitCustomItemDrawer}
          ></CustomItemDetail>

        </WrappedDrawer>

      </div>

    </>
  );
};