/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */


export default class GenUtil {


  public static isEqual(s1: string, s2: string) {
    return s1.trim().toLowerCase() === s2.trim().toLowerCase();
  }


  public static NVL(s1: string, s2: string) {
    if (this.safeTrim(s1).length > 0) {
      return s1;
    }
    else {
      return s2;
    }
  }


  public static safeTrim(s: object | string | number | boolean | undefined | null) {
    if (!!s) {
      if (typeof (s) == 'string') {
        return (s as string).trim();
      }
      else if (typeof (s) == 'number') {
        return (s as number).toString().trim();
      }
      else if (typeof (s) == 'boolean') {
        return (s as boolean).toString().trim();
      }
      else {
        return (s as object).toString();
      }
    }
    else {
      return '';
    }
  }


  public static safeToBool(x: boolean | string | undefined | null): boolean {

    if (x == null || typeof x === 'undefined') {
      return false;
    }
    else if (typeof x === 'string') {
      if (x.trim().toLowerCase() === "true" || x.trim().toLowerCase() === "true" || x.trim().toLowerCase() === "1") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return x;
    }
  }


  public static safeToNumber(x: string | number | undefined | null): number {

    if (x == null || typeof x === 'undefined') {
      return 0;
    }
    else if (typeof x === 'string') {
      let t = 0;
      if (this.safeTrim(x) === '') {
        t = 0;
      }
      else if ((x + '').indexOf('.') >= 0 || (x + '').indexOf(',') >= 0 || (x + '').indexOf('$') >= 0 || (x + '').indexOf('%') >= 0) {
        t = parseFloat((x + '').replace(/,/ig, '').replace(/\$/ig, '').replace(/\%/ig, ''));
      }
      else {
        t = parseInt(x);
      }

      if (isNaN(t)) return 0;
      else if (!isFinite(t)) return 0;
      else return t;
    }
    else {
      return x;
    }
  }


  public static safeToMomentOrNull(x: moment.Moment | undefined | null): moment.Moment | null {

    if (x == null || typeof x === 'undefined') {
      return null;
    }
    else {
      return x;
    }
  }


  public static isNull(s: object | string | number | boolean | undefined | null) {
    let tmp = GenUtil.safeTrim(s);
    return !!tmp ? false : true;
  }


  public static normalizeEOL(str: string | null): string {
    if (!!str) {
      return str.replace(/\r\n|\n\r|\n|\r/g, '\n');
    }
    else {
      return '';
    }
  }


  public static getUrlParameter(name: string): string {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };


  public static getWindowHeight(): number {
    let w: any = window,
      d: any = document,
      e: any = d.documentElement,
      g: any = d.getElementsByTagName('body')[0],
      y: any = w.innerHeight || e.clientHeight || g.clientHeight;

    return parseInt(y);
  }


  public static getWindowWidth(): number {
    let w: any = window, d: any = document, e: any = d.documentElement, g: any = d.getElementsByTagName('body')[0], x: any = w.innerWidth || e.clientWidth || g.clientWidth;

    return parseInt(x);
  }


  public static isBrowserIE(): boolean {
    let bd: any = (window as any).BrowserDetection; // comes from SharePoint init.js

    if (typeof bd != 'undefined') {
      if (bd.userAgent.ie || bd.userAgent.ie5up || bd.userAgent.ie7up || bd.userAgent.ie8standardUp || bd.userAgent.ie9standardUp || bd.userAgent.ie10standardUp || parseInt(bd.userAgent.iever) > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }


  public static isInt(a: string) {
    let b = a.trim();

    let isvalid = /^\d+$/gi.test(b);

    return isvalid;
  }


  public static isGuid(str: string): boolean {
    if (!!str === false) {
      return false;
    }
    else if (str.trim().length < 10) {
      return false;
    }

    str = str.trim();

    if (str[0] === '{') {
      str = str.substring(1, str.length - 1);
    }

    let regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return regexGuid.test(str);
  }


  public static cacheBust = (): string => {
    return 'tid=_' + Date.now().toString();
  }


  public static escapeApos(val: string) {
    return val.replace(/'/g, '%2527')
  }


  public static validateEmail(email: string) {
    //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let re = /\S+@\S+\.\S+/;
    // simple expression: /\S+@\S+\.\S+/
    return re.test(String(email).toLowerCase());
  }


  public static stripHtml(s: string) {
    return s.replace(/(<([^>]+)>)/ig, "");
  }


  public static stripControlChars(s: string) {
    return s.replace(/\r|\n|\t/ig, "");
  }


  public static mergeSpaces(s: string) {
    return s.replace(/\s+/ig, " ");
  }


  public static combinePaths(...args: any[]) {
    return args.map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/[\/]*$/g, '')
      } else {
        return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
      }
    }).filter(x => x.length).join('/')
  }


  public static capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  public static capitalizeFirstLetterEachWord(str: string) {

    let splitStr = str
      //.toLowerCase()
      //.replace(/-/ig, ' ')
      .replace(/_/ig, ' ')
      .split(' ');

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
  }


  public static generateGuid() {
    //uuidv4
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line no-mixed-operators
      return v.toString(16);
    });
  }


  public static rot13Transform(str: string): string {

    let input = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
    let index = (x: any) => input.indexOf(x);
    let translate = (x: any) => index(x) > -1 ? output[index(x)] : x;
    return str.split('').map(translate).join('');
  }


  public static boolToHtmlYesNo(x: boolean): any {
    return x ? 'Yes' : 'No'
  }


  public static boolToHtmlYesNoNa(x: boolean | null): any {
    return x == null ? 'N/A' : x ? 'Yes' : 'No';
  }


  public static dateToString(x: Date | null): string {
    if (!!x) {
      return new Date(x).toLocaleString().replace(/,/ig, '');
    }
    else {
      return '';
    }
  }


  public static dateOnlyToString(x: Date | null): string {
    if (!!x) {
      return new Date(x).toLocaleDateString().replace(/,/ig, '');
    }
    else {
      return '';
    }
  }


  public static dateOnlyToString2(x: Date | null): string {
    if (!!x) {
      let t = new Date(x).toLocaleDateString("en-US", { weekday: 'narrow', year: 'numeric', month: 'long', day: 'numeric' }).slice(3);
      return t;
    }
    else {
      return '';
    }
  }


  public static numberToPrint(num: number | null | undefined, decPlaces: number, prefix: string, suffix: string, removeTrailingZero: boolean = false): string {

    if (num === null || num === undefined) {
      return '';
    }
    else {
      // rounding will occur: 55.554 => 55.55, 55.556 => 55.56
      let t = num.toFixed(decPlaces).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      if (removeTrailingZero) t = t.replace(/0+$/, '');
      return prefix + t + suffix;
    }
  }


  public static numberToCurrency(x: number | null | undefined, includeCurSymb: boolean = true): string {

    if (x === null || x === undefined) {
      return '';
    }
    else {
      // rounding will occur: 55.554 => 55.55, 55.556 => 55.56
      return (includeCurSymb ? '$' : '') + x.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }
  }


  public static getFormLayoutDirection(): any {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 1200 ? 'horizontal' : 'vertical';
  }


  public static convertStrToList(s: string, sep: string = '|'): string[] {

    let t = s;

    if (t == null || typeof t === 'undefined' || t.trim().length === 0) {
      return [];
    }
    else {
      let parts = t.trim().split(sep);

      return parts;
    }
  }


  public static padLeadingZeros = (num: string, size: number) => {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  }


}
