/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Alert,
  Layout,
  Spin,
  Tabs,
  Typography,
} from 'antd';

import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import {
  ClientPrincipal,
  PriceListItemCollection,
} from '../../Library/Models';
import { useSessionStorage } from '../../Library/UseStorageHooks';
import WebApiHelper from '../../Library/WebApiHelper';
import { ImportData } from './ImportData';
import { QuotesList } from './QuotesList';
import { TermCompareTool } from './TermCompareTool';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;


export interface IHomeProps {
  curUser: ClientPrincipal;
}

export const Home: React.FunctionComponent<IHomeProps> = (props: React.PropsWithChildren<IHomeProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[Home] mounted!');
  }, []);

  const [selTabKey, setSelTabKey] = useState('1');
  const [loading, setLoading] = useState(true);
  const [priceListItemsCol, setPriceListItemsCol] = useState<PriceListItemCollection | null>(null);
  const [cachePriceListItemsCol, setCachePriceListItemsCol] = useSessionStorage<PriceListItemCollection | null>("__cachePriceListItemsCol", null);


  useEffect(() => {
    // on page load, get all pricelistitems (from cache, then from db)
    let ignore = false;

    setLoading(true);

    setTimeout(() => {

      (async () => {

        if (Consts.USE_PL_CACHE && cachePriceListItemsCol != null) {
          // cache found, load from cache
          let data = cachePriceListItemsCol;

          if (!ignore) setPriceListItemsCol(data);

          console.log('Getting PriceListItemsCol from cache!');

          if (!ignore) setLoading(false);

        }
        else {
          // cache NOT found, get from websvc
          let getPLItemsResp = await WebApiHelper.get1(`/api/web-svc?action=${'COSMOSDBQUERY'}&c=${Consts.CONTAINER_PRICELISTITEMS}&q=${encodeURIComponent('SELECT * FROM c ORDER BY c._ts DESC OFFSET 0 LIMIT 1')}`);

          if (getPLItemsResp.isErr) {
            AppHelper.showMessageErrorBar(getPLItemsResp.msg);
          }
          else {
            if (getPLItemsResp.data.length > 0) {
              let plItems = getPLItemsResp.data[0];

              if (!ignore) setPriceListItemsCol(plItems);
              if (!ignore) setCachePriceListItemsCol(plItems);
              console.log('Saving PriceListItemsCol to cache!');
            }
          }

          if (!ignore) setLoading(false);
        }

      })();
    }, 100);

    return () => { ignore = true; }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);


  function handleTabChange(key: any) {
    setSelTabKey(key);
  }

  function updatePriceListItemsCol(item: PriceListItemCollection) {
    setPriceListItemsCol(item);
    setCachePriceListItemsCol(item);
    console.log('Updating PriceListItemsCol cache!');
  }

  let lastUpdate = new Date(Number(priceListItemsCol?._ts) * 1000);
  let oldPricingData: boolean = (lastUpdate.getMonth() !== new Date().getMonth()) || (lastUpdate.getFullYear() !== new Date().getFullYear())
  return (
    <>

      {
        loading && (
          <div className='wbs25 align-center'>
            <Spin tip="Loading..."></Spin>
          </div>
        )
      }

      {
        !loading && (
          <div className='wbs25'>
            {oldPricingData &&
              <Alert message={`Pricing Data may be out of date -- Last Pricing Data Import: ${lastUpdate.toDateString()}`} type="warning" showIcon />

            }
            {!oldPricingData &&
              <div style={{ fontStyle: 'italic', }}>
                {
                  `Last Pricing Data Import: ${lastUpdate.toDateString()}`
                }

              </div>
            }
            <Tabs defaultActiveKey={selTabKey} onChange={handleTabChange}>

              <TabPane tab="Saved Quotes" key="1">
                {
                  selTabKey === '1' && (
                    <div className='wbs25'>

                      {
                        (priceListItemsCol == null || priceListItemsCol.items == null || priceListItemsCol.items.length <= 0) ? (
                          <Alert message="Please import some price list items to continue." type="warning" showIcon />
                        ) : (
                          <QuotesList curUser={props.curUser} priceListItemsCol={priceListItemsCol}></QuotesList>
                        )
                      }

                    </div>
                  )
                }
              </TabPane>

              <TabPane tab="Term Compare Tool" key="2">
                {
                  selTabKey === '2' && (
                    <div className='wbs25'>
                      {
                        (priceListItemsCol == null || priceListItemsCol.items == null || priceListItemsCol.items.length <= 0) ? (
                          <Alert message="Please import some price list items to continue." type="warning" showIcon />
                        ) : (
                          <TermCompareTool priceListItemsCol={priceListItemsCol}></TermCompareTool>
                        )
                      }
                    </div>
                  )
                }
              </TabPane>

              <TabPane tab="Import Data" key="3">
                {
                  selTabKey === '3' && (
                    <div className='wbs25'>
                      <ImportData curUser={props.curUser} updatePriceListItemsCol={updatePriceListItemsCol}></ImportData>
                    </div>
                  )
                }
              </TabPane>

              {/* <TabPane tab="Manage Users" key="3">
                {
                  selTabKey === '3' && (
                    <div className='wbs25'>
                      <h3>TBD: User Management:</h3>
                    </div>
                  )
                }
              </TabPane> */}

              {/*
              <TabPane tab="DB Admin" key="4">
                {
                  selTabKey === '4' && (
                    <div className='wbs25'>
                      <DBAdmin curUser={props.curUser}></DBAdmin>
                    </div>
                  )
                }
              </TabPane>
*/}

            </Tabs>

          </div>

        )
      }

    </>
  );
};