/* eslint-disable no-unused-vars */
import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Spin,
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';
import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import aptosDisplay from '../../Fonts/Aptos-Display.ttf';
import aptosMono from '../../Fonts/Aptos-Mono.ttf';
import aptosSemibold from '../../Fonts/Aptos-Semibold.ttf';
import imgBandRLogo from '../../Library/bandrlogo';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';

const fontName = 'Aptos';

Font.register({
  family: fontName,
  fonts: [
    {
      src: aptosDisplay
    },
    {
      src: aptosSemibold,
      fontWeight: 600
    },
    {
      src: aptosMono,
      fontStyle: 'italic'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    padding: 50,
    backgroundColor: '#fff',
    fontFamily: fontName,
    fontSize: 11

  },
  container: {

  },
  table: {
    display: "table",
    width: "100%"
  },
  totalTable: {
    width: "100%",
    display: "table",
    marginTop: 24,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowAlt: {
    flexDirection: "row",
    backgroundColor: "#E4EEF3",
  },
  tableColHeader: {
    padding: 8,
    fontStyle: 'italic',
  },
  tableCol: {
    padding: 8,
  },
  tableColAlt: {
    padding: 8,
    backgroundColor: "#E4EEF3",
  },
  tableCol33: {
    width: "33%"
  },
  tableCol50: {
    width: "50%"
  },
  tableCol100: {
    width: "100%"
  },
  brBusinessInfo: {
    textAlign: 'right',
    color: '#7F7F7F',
    paddingBottom: 4,
    fontSize: 9
  },
  quoteHeader: {
    textAlign: 'center',
    color: '#005C9A',
    marginTop: 16,
    marginBottom: 16,
    fontSize: 13,
    fontStyle: 'italic'
  },
  quoteInfoLabel: {
    marginTop: 8,
    fontWeight: 600
  },
  quoteInfoData: {
    marginTop: 8
  },
  quoteSubject: {
    marginTop: 20,
    fontSize: 14,
    fontWeight: 600
  },
  termsFooter: {
    marginTop: 24,
    fontSize: 12,
    textAlign: 'center'
  }
});


function PDFExport(props) {

  useEffect(() => {
    // Force loading and wait for loading to finish
    Promise.all([
      Font.load({ fontFamily: fontName }),
      Font.load({ fontFamily: fontName, fontWeight: 600 }),
      Font.load({ fontFamily: fontName, fontStyle: 'italic' })
    ]).then(() => {
      setLoading(false);
    })
  }, []);

  const [loading, setLoading] = useState(true);


  function renderStateAbbrev(usState) {
    // convert the long state name back to state abbrev, using the consts value loaded from runtimeconfig.js
    let idx = Consts.GET_STATES().findIndex((o) => o.name === usState);
    let abbrev = Consts.GET_STATES()[idx].abbrev;
    return abbrev;
  }

  const MyDocument = () => {
    let hideDuration = true;
    props.curQuote.salesItems.forEach(salesItem => {
      if (salesItem.termDuration && salesItem.billingPlan) {
        hideDuration = false;
      }
    });

    return (
      <Document title={`${props.curQuote.quoteNumber} - ${props.curQuote.subject}.pdf`}>
        <Page size="LETTER" style={styles.page}>
          <View style={[styles.container]}>
            <View style={[styles.table]}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol50]}>
                  <Image src={imgBandRLogo} style={{ width: 152, height: 36 }}></Image>
                </View>
                <View style={[styles.tableCol50]}>
                  <Text style={[styles.brBusinessInfo]}>524 Brighton Ave. Unit 2, Spring Lake, NJ 07762</Text>
                  <Text style={[styles.brBusinessInfo]}>Phone: 732.845.5002 Fax: 732.845.8145</Text>
                  <Text style={[styles.brBusinessInfo]}>bandrsolutions.com</Text>
                </View>
              </View>
            </View>

            <View style={[styles.table]}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol100]}>
                  <Text style={[styles.quoteHeader]}>QUOTATION</Text>
                </View>
              </View>
            </View>

            <View style={[styles.table]}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol33]}>
                  <Text style={[styles.quoteInfoLabel]}>Ship To/Bill To:</Text>
                  <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.busName1}</Text>
                  {props.curQuote.brClient.busName2.length > 0 &&
                    <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.busName2}</Text>
                  }
                  <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.street1}</Text>
                  {props.curQuote.brClient.street2.length > 0 &&
                    <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.street2}</Text>
                  }
                  <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.city}, {renderStateAbbrev(props.curQuote.brClient.usState)} {props.curQuote.brClient.zip}</Text>
                </View>
                <View style={[styles.tableCol33]}>
                  <Text style={[styles.quoteInfoLabel]}>Quote Number:</Text>
                  <Text style={[styles.quoteInfoData]}>{props.curQuote.quoteNumber}</Text>
                  <Text style={[styles.quoteInfoLabel]}>Contact:</Text>
                  <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.contactName1}</Text>
                  {props.curQuote.brClient.contactName2.length > 0 &&
                    <Text style={[styles.quoteInfoData]}>{props.curQuote.brClient.contactName2}</Text>
                  }
                </View>
                <View style={[styles.tableCol33]}>
                  <Text style={[styles.quoteInfoLabel]}>Quote Date:</Text>
                  <Text style={[styles.quoteInfoData]}>{GenUtil.dateOnlyToString2(props.curQuote.quoteDate)}</Text>
                  <Text style={[styles.quoteInfoLabel]}>Quote Expiration:</Text>
                  <Text style={[styles.quoteInfoData]}>{GenUtil.dateOnlyToString2(props.curQuote.quoteExpires)}</Text>
                </View>
              </View>
            </View>

            <View style={[styles.table]}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol100, { paddingBottom: 12 }]}>
                  <Text style={[styles.quoteSubject]}>{props.curQuote.subject}</Text>
                </View>
              </View>
            </View>

            <View style={[styles.table]}>
              <View style={[styles.tableRow, { borderBottom: '1px solid black' }]}>
                <View style={[styles.tableColHeader, { width: "8%", textAlign: "right" }]}>
                  <Text>QTY</Text>
                </View>
                <View style={[styles.tableColHeader, { width: "40%" }]}>
                  <Text>DESCRIPTION</Text>
                </View>
                {!hideDuration &&
                  <>
                    <View style={[styles.tableColHeader, { width: "12%" }]}>
                      <Text>BILLED</Text>
                    </View>
                    <View style={[styles.tableColHeader, { width: "8%" }]}>
                      <Text>TERM</Text>
                    </View>
                  </>
                }
                <View style={[styles.tableColHeader, { width: "16%", textAlign: "right" }]}>
                  <Text>RATE</Text>
                </View>
                <View style={[styles.tableColHeader, { width: "16%", textAlign: "right" }]}>
                  <Text>TOTAL</Text>
                </View>
              </View >
              {
                props.curQuote.salesItems.map((o, i) => (
                  <View style={[i % 2 ? styles.tableRowAlt : styles.tableRow]} key={i} wrap={false}>
                    <View style={[i % 2 ? styles.tableColAlt : styles.tableCol, { width: "8%", textAlign: "right" }]}>
                      <Text>{new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(o.quantity)}</Text>
                    </View>
                    <View style={[i % 2 ? styles.tableColAlt : styles.tableCol, { width: "40%" }]}>
                      <Text>{o.description}</Text>
                    </View>
                    {!hideDuration &&
                      <>
                        <View style={[i % 2 ? styles.tableColAlt : styles.tableCol, { width: "12%" }]}>
                          <Text>{o.billingPlan}</Text>
                        </View>
                        <View style={[i % 2 ? styles.tableColAlt : styles.tableCol, { width: "8%" }]}>
                          <Text>{o.termDuration}</Text>
                        </View>
                      </>
                    }
                    <View style={[i % 2 ? styles.tableColAlt : styles.tableCol, { width: "16%", textAlign: "right" }]}>
                      <Text>{GenUtil.numberToCurrency(o.sellPrice, true)}</Text>
                    </View>
                    <View style={[i % 2 ? styles.tableColAlt : styles.tableCol, { width: "16%", textAlign: "right" }]}>
                      <Text>{GenUtil.numberToCurrency(o.totalSale, true)}</Text>
                    </View>
                  </View>
                ))
              }
            </View >

            <View style={[styles.totalTable]}>
              {props.curQuote.taxRate > 0 &&
                <>
                  <View style={styles.tableRow} wrap={false}>
                    <View style={[styles.tableColHeader, { width: "84%", textAlign: "right" }]}>
                      <Text>SUBTOTAL</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "16%", textAlign: "right" }]}>
                      <Text>{GenUtil.numberToCurrency(props.curQuote.totalSale, true)}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow} wrap={false}>
                    <View style={[styles.tableColHeader, { width: "84%", textAlign: "right" }]}>
                      <Text>{`${props.curQuote.taxLabel} (${GenUtil.numberToPrint(props.curQuote.taxRate, 4, '', '%', true)})`}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "16%", textAlign: "right" }]} wrap={false}>
                      <Text>{GenUtil.numberToCurrency(props.curQuote.totalSale * (props.curQuote.taxRate / 100), true)}</Text>
                    </View>
                  </View>
                </>
              }
              <View style={styles.tableRow} wrap={false}>
                <View style={[styles.tableColHeader, { width: "84%", textAlign: "right" }]}>
                  <Text>TOTAL</Text>
                </View>
                <View style={[styles.tableCol, { width: "16%", textAlign: "right" }]}>
                  <Text>{GenUtil.numberToCurrency(props.curQuote.totalSale * ((props.curQuote.taxRate / 100) + 1), true)}</Text>
                </View>
              </View>
            </View>

            {
              props.curQuote.showTermsFooter === true && (
                <View style={[]} wrap={false}>
                  <Text style={[styles.termsFooter]}>
                    {Consts.GET_TERMS_FOOTER()}
                  </Text>
                </View>
              )

            }
          </View >
        </Page >
      </Document >
    );
  };

  return (
    <>
      {
        loading && (
          <div className='wbs25 align-center'>
            <Spin tip="Rendering..."></Spin>
          </div>
        )
      }
      {
        !loading && (
          <>
            <div className='wbs25'>
              <PDFDownloadLink document={<MyDocument />} fileName={`${props.curQuote.quoteNumber} - ${props.curQuote.subject}.pdf`}>
                {({ loading }) =>
                  !loading && <Button type='primary' icon={<DownloadOutlined />}>Download</Button>
                }
              </PDFDownloadLink>
            </div>

            <div className='wbs25'>
              <PDFViewer style={{ width: '100%', height: 800, border: '0 none', padding: 0, margin: 0 }}>
                <MyDocument />
              </PDFViewer>
            </div>
          </>
        )
      }
    </>
  );
}

export default PDFExport;