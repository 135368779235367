/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import {
  message,
  notification,
  Button,
  Modal,
  Tag
} from 'antd';

import * as Consts from './Consts';
import GenUtil from './GenUtil';
import * as Models from './Models';

export declare type ISortOrder = 'descend' | 'ascend';


export default class AppHelper {

  // =======================================

  public static getStatusTagColor = (status: string) => {
    return status === Consts.ENUM_STATUS.DRAFT ? "#1BA1E2" :
      status === Consts.ENUM_STATUS.REJECTED ? "#E51400" :
        status === Consts.ENUM_STATUS.SUBMITTED ? "#F0A30A" :
          status === Consts.ENUM_STATUS.CLOSED ? "#60A917" :
            status === Consts.ENUM_STATUS.DEAD ? "#647687" : "#647687";
  }

  public static getStatusTag = (status: string, fn: any | null) => {
    return (
      <Tag
        style={fn == null ? undefined : { cursor: 'pointer' }}
        onClick={fn}
        color={AppHelper.getStatusTagColor(status)}>{status}</Tag>
    );
  }

  // =======================================

  public static calcSalesItemsTotals = (items: Models.SalesItem[]) => {

    let tot_qty = 0;
    let tot_tc = 0;
    let tot_ts = 0;
    let tot_pr = 0;

    items.forEach(x => {
      tot_qty += x.quantity;
      tot_tc += x.totalCost;
      tot_ts += x.totalSale;
      tot_pr += x.profit;
    });

    const tot_ma = (tot_pr / tot_ts) * 100;

    return {
      tot_qty, tot_tc, tot_ts, tot_pr, tot_ma
    };
  }

  // =======================================

  public static MakePlainText = (val: string) => {

    let result = GenUtil.mergeSpaces(GenUtil.stripControlChars(GenUtil.stripHtml(val))).trim();

    result = result.replace(/&[\w\d]+;/ig, ''); // will match '&amp;' or '&quot;'
    result = result.replace(/#[\w\d]+;/ig, ''); // will match '#39;'  
    result = result.replace(/amp;/ig, '');
    result = result.replace(/quot;/ig, '');

    return result;
  }

  // =======================================

  public static showMessageSuccessBar = (msg: string) => {
    message.success(msg, Consts.ANT_MESSAGE_AUTOCLOSE_DELAY);
  };

  public static showMessageSuccessModal = (msg: string) => {

    if (msg.length > 600) {
      msg = msg.substr(0, 600) + '...';
    }

    notification.success({
      message: 'SUCCESS',
      description: msg,
      duration: 30
    });
  };

  public static showMessageErrorBar = (msg: string) => {

    console.error(msg);

    message.error(msg, Consts.ANT_MESSAGE_AUTOCLOSE_DELAY);
  };

  public static showMessageErrorModal = (msg: string) => {
    // show regular noti with abbrev error message displayed, plus a button for show more, onclick will open a modal to display full error in detail for screenshot

    console.error(msg);

    let smsg = msg;

    if (msg.length > 600) {
      smsg = msg.substr(0, 600) + '...';
    }

    let key = (new Date()).toISOString();

    notification.error({
      message: 'ERROR',
      className: 'pwAntNotiError',
      key: key,
      description: (
        <>
          <div>{smsg}</div>
          <div>
            <Button type='link' onClick={() => {

              // close noti message
              notification.close(key);

              // open error modal with full error details
              Modal.error({
                visible: true,
                title: 'Application Error Details',
                width: '75%',
                okText: 'Close',
                content: (
                  <div className='tip errorModalDetails'>
                    {msg}
                  </div>
                )
              });

            }}>Show more...</Button></div>
        </>
      ),
      duration: 60 * 5 // sec * num
    });
  };

  public static showMessageWarningBar = (msg: string) => {
    message.warning(msg, Consts.ANT_MESSAGE_AUTOCLOSE_DELAY);
  };

  public static showMessageWarningModal = (msg: string) => {

    if (msg.length > 600) {
      msg = msg.substr(0, 600) + '...';
    }

    notification.warning({
      message: 'WARNING',
      description: msg,
      duration: 30
    });
  };

  // =======================================

  private static createDSIH = (html: string): any => {
    return { __html: html };
  };

  public static renderDSIH = (html: string): any => {
    return <div dangerouslySetInnerHTML={AppHelper.createDSIH(html)} />;
  };

  // =======================================

}
