import './App.css';

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Layout,
  Result,
  Space,
  Spin,
  Typography,
} from 'antd';

import { LockOutlined } from '@ant-design/icons';

import { Home } from './Components/Sections/Home';
import AppHelper from './Library/AppHelper';
import * as Consts from './Library/Consts';
import {
  AuthResp,
  ClientPrincipal,
} from './Library/Models';
import WebApiHelper from './Library/WebApiHelper';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;


function App() {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[App] mounted!');
    console.log('Client version: ' + Consts.APP_VERSION);
  }, []);


  const [loading, setLoading] = useState(true);

  const [curUser, setCurUser] = useState<ClientPrincipal | null>(null);

  const [isAuth, setIsAuth] = useState(false);


  useEffect(() => {
    let ignore = false;

    setLoading(true);

    setTimeout(() => {

      (async () => {

        const authResp1: any = await WebApiHelper.get1(`/.auth/me`);

        /*
        results when not auth:
          {"clientPrincipal":null}
        results when auth:
          {"clientPrincipal":{"userId":"9de93b8b6634dfcb5dee515b38f3d469","userRoles":["anonymous","authenticated"],"identityProvider":"aad","userDetails":"bentest"}}
        */

        if (authResp1 != null && typeof authResp1.clientPrincipal !== 'undefined' && authResp1.clientPrincipal != null) {

          let respCP = authResp1.clientPrincipal;

          let tmpCP: ClientPrincipal = {
            identityProvider: respCP.identityProvider,
            userDetails: respCP.userDetails,
            userId: respCP.userId,
            userRoles: respCP.userRoles
          }

          if (!ignore) setCurUser(tmpCP);

          // user identity found, lets auth using azure function
          const authResp2 = await WebApiHelper.get1(`/api/web-svc?action=AUTHCHECK`);

          if (authResp2.isErr) {
            AppHelper.showMessageErrorBar('ERROR: ' + authResp2.msg);
          }
          else {
            let userAuth: AuthResp = authResp2.data;

            if (userAuth.isAuth === true) {
              // user is auth

              if (!ignore) setIsAuth(true);

            }
          }
        }

        if (!ignore) setLoading(false);

      })();
    }, 100);

    return () => { ignore = true; }
  }, []);


  function LoginComp(props: any) {

    if (loading) {
      return (<span>Loading...</span>);
    }

    if (curUser == null) {
      return (
        <>
          <Button type={props.btype} icon={props.btype === 'link' ? undefined : <LockOutlined />} onClick={() => { window.open('/.auth/login/aad', '_self'); }}>Login</Button>
        </>
      );
    }
    else {
      return (
        <>
          <span>{`Welcome ${curUser.userDetails}, `}</span>
          <Button type="link" onClick={() => { window.open('/.auth/logout', '_self'); }}>Logout</Button>
          {/* icon={<UnlockOutlined />} */}
          <span>&nbsp;[<Button type="link" onClick={() => { window.open('/.auth/purge/aad', '_self'); }}>Purge App Auth</Button>]</span>
          {/* icon={<ClearOutlined />} */}
        </>
      );
    }
  }


  return (
    <Layout className="layout">

      <Header style={{ backgroundColor: "#005c99" }}>
        <Space align='center'>
          <div className='logo'>
            <a href='/index.html' style={{ border: '0 none' }}><img src='/images/br-horizontal.png' style={{ border: '0 none' }} title='logo' alt='logo'></img></a>
          </div>
          <Title level={2} className='headerTitle' >B&amp;R Quote Builder</Title>
        </Space>
        <div className='loginContainer'>
          <LoginComp btype='primary'></LoginComp>
        </div>
      </Header>

      <Content style={{ margin: '16px 0', padding: '0 50px' }}>
        <div className="site-layout-content">

          <div className='wrapper25'>

            {
              loading && (
                <div className='wbs25 align-center'>
                  <Spin tip="Loading..."></Spin>
                </div>
              )
            }

            {
              !loading && (
                <div className='wbs25'>

                  {
                    curUser && isAuth && (
                      <Home curUser={curUser}></Home>
                    )
                  }

                  {
                    curUser && !isAuth && (
                      <>
                        <Result
                          status="error"
                          title="You are not authorized to use this application."
                        />
                      </>
                    )
                  }

                  {
                    !curUser && (
                      <>
                        <Title level={2}>Welcome to the B&amp;R Quote Builder</Title>

                        <div className='wbs25'>
                          Please <LoginComp btype='link'></LoginComp> to continue.
                        </div>
                      </>
                    )
                  }

                </div>
              )
            }

          </div>

        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>B&amp;R Business Solutions - ©{(new Date()).getFullYear()}</Footer>
    </Layout>
  );
}

export default App;
