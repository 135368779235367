/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import {
  Drawer
} from 'antd';

import * as Consts from "../../Library/Consts";


export interface IWrappedDrawer {
  closable: boolean;
  title: string;
  showDrawer: boolean;
  children: any;
  width?: string | number;
  handleCancelDrawer: () => void;
}

export const WrappedDrawer: React.FunctionComponent<IWrappedDrawer> = (props: IWrappedDrawer) => {

  useEffect(() => {
    //Consts.DEBUG_LOG_DIDMOUNT && console.log('[WrappedDrawer] mounted', props.title);
  }, [props.title]);


  return (
    <>
      <Drawer
        closable={props.closable}
        destroyOnClose={true}
        mask={true}
        maskClosable={false}
        keyboard={false}
        title={props.title}
        width={props.width ? props.width : 720}
        onClose={props.handleCancelDrawer}
        visible={props.showDrawer}>

        {props.children}

      </Drawer>
    </>
  );
};

export default WrappedDrawer;