/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
import XLSX from 'xlsx';

export default function ExcelToJSON() {

  this.parseExcel = function (file, sheetName, includeHeader, callback) {
    var reader = new FileReader();

    reader.onload = function (e) {
      var data = e.target.result;

      var workbook = XLSX.read(data, {
        type: 'binary'
      });

      var sheetFound = false;

      workbook.SheetNames.forEach(function (_sheetName) {
        if (!sheetFound && _sheetName === sheetName) {
          // Here is your object

          //let includeHeader = 1;
          // when =1, array of arrays is returned (this is good for using column indexes)
          // when =0, array of objects is returned with real property names (this is good for using column header row names as property names)

          //var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[_sheetName]);            
          var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[_sheetName], { header: includeHeader ? 1 : 0, defval: null });

          // console.log(XL_row_object);
          // console.log(XL_row_object.length);

          // data comes back as array of objects, each object has property names matching header rows
          // sheet_to_row_object_array IS THE SAME AS sheet_to_json, its an alias

          // var jsonStr = JSON.stringify(XL_row_object);
          //setOutput(jsonStr);

          callback(XL_row_object);

          sheetFound = true;

          //alert('DONE');
        }
      });

      if (!sheetFound) callback([]);
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  };
};
