/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import _ from 'lodash';

import {
  CheckOutlined,
  CloseOutlined,
  SaveOutlined,
} from '@ant-design/icons';

// import WebApiHelper from '../../Library/WebApiHelper';
import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';
import {
  ClientPrincipal,
  PriceListItem,
  PriceListItemCollection,
  SalesItem,
} from '../../Library/Models';

const { Title } = Typography;
const { Option } = Select;


/* *********************************************************
THIS COMPONENT SHOULD NOT MAKE API CALLS
********************************************************* */

export interface ISalesItemDetailProps {
  curUser: ClientPrincipal;
  isAnnual: boolean;
  priceListItemsCol: PriceListItemCollection;
  curSalesItem: SalesItem;
  handleSubmitDrawer: (data: SalesItem, isNew: boolean) => void;
  handleCancelDrawer: () => void;
}

export const SalesItemDetail: React.FunctionComponent<ISalesItemDetailProps> = (props: React.PropsWithChildren<ISalesItemDetailProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[SalesItemDetail] mounted!');
  }, []);


  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selPriceLevel, setSelPriceLevel] = useState<string>('CORPORATE');
  const [selPriceListItemId, setSelPriceListItemId] = useState<number>(0);
  const [totalSale, setTotalSale] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [profit, setProfit] = useState(0);
  const [margin, setMargin] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [priceItemWarning, setPriceItemWarning] = useState<string>('');


  useEffect(() => {

    const si = props.curSalesItem;

    if (si.itemId !== '') {
      setSelPriceLevel(si.licAgrType);
      setSelPriceListItemId(si.id);
      form.setFieldsValue({
        description: si.description,
        productId: si.productId,
        brCost: GenUtil.numberToCurrency(si.brCost * (props.isAnnual ? 12 : 1), true),
        msrp: GenUtil.numberToCurrency(si.msrp * (props.isAnnual ? 12 : 1), true),
        quantity: si.quantity,
        sellPrice: GenUtil.numberToCurrency(si.sellPrice, false),
        termDuration: si.termDuration,
        billingPlan: si.billingPlan,
        source: si.source
      });

      let { ts, tc, p, m } = calculateTotals(si.quantity, si.sellPrice, si.brCost * (props.isAnnual ? 12 : 1));

      setTotalSale(ts);
      setTotalCost(tc);
      setProfit(p);
      setMargin(m);
      checkPriceListItem(si);

      //check if the current sales item pricing data is different than int he price list database
      //in case something changed since the item was created
      let selPriceListItem = props.priceListItemsCol.items.filter(
        x =>
          x.billingPlan.toLowerCase() === si.billingPlan.toLowerCase() &&
          x.description.toLowerCase() === si.description.toLowerCase() &&
          x.licAgrType.toLowerCase() === si.licAgrType.toLowerCase() &&
          x.productId.toLowerCase() === si.productId.toLowerCase() &&
          x.source.toLowerCase() === si.source.toLowerCase() &&
          x.termDuration.toLowerCase() === si.termDuration.toLowerCase()
      )[0];

      if (!selPriceListItem) {
        setPriceItemWarning(`This Sales Item was not found in the database, it may have been removed.  Please double check that this item is still valid`);
        return;
      }

      if (selPriceListItem.msrp !== si.msrp || selPriceListItem.brCost !== si.brCost) {
        setPriceItemWarning(`Pricing information may have changed for this sales item!  Please double check the pricing information and if it has changed, delete and re-add this item`);
      }

      console.log('sales item', si);
      console.log('matching price list item', selPriceListItem);
    }

  }, [props.curSalesItem, props.isAnnual, form]);

  function checkPriceListItem(salesItem: SalesItem) {

  }

  function handleOnFinish(formValues: any) {
    if (loading) return;

    setLoading(true);
    setTimeout(() => {

      (async () => {

        let priceListItem: PriceListItem | null = null;
        if (props.curSalesItem.itemId === '') {
          //for new items get price list data from database, unless it is a custom item, then get directly from fields
          priceListItem = props.priceListItemsCol.items.filter(x => x.id === selPriceListItemId)[0];
          if (!priceListItem) {
            priceListItem = {
              billingPlan: form.getFieldValue('billingPlan'),
              brCost: GenUtil.safeToNumber(formValues.brCost),
              description: form.getFieldValue('description'),
              id: -1,
              licAgrType: "CUSTOM",
              msrp: GenUtil.safeToNumber(formValues.msrp),
              productId: form.getFieldValue('productId'),
              source: form.getFieldValue('source'),
              termDuration: form.getFieldValue('termDuration')
            }
          }
        }
        else {
          //for editing items, get all data from the currently saved item
          //note that editing custom items has it's own form 'CustomItemDetail' and so only non custom items are edited here
          priceListItem = {
            billingPlan: props.curSalesItem.billingPlan,
            brCost: props.curSalesItem.brCost,
            description: props.curSalesItem.description,
            id: props.curSalesItem.id,
            licAgrType: props.curSalesItem.licAgrType,
            msrp: props.curSalesItem.msrp,
            productId: props.curSalesItem.productId,
            source: props.curSalesItem.source,
            termDuration: props.curSalesItem.termDuration
          }
        }

        let quantity = GenUtil.safeToNumber(formValues.quantity);
        let sellPrice = GenUtil.safeToNumber(formValues.sellPrice);

        // extra validation
        if (sellPrice < 0) {
          AppHelper.showMessageWarningBar(`Sell Price cannot be less than zero.`);
          setLoading(false);
          return;
        }
        if (quantity <= 0) {
          AppHelper.showMessageWarningBar(`Quantity must be greater than zero.`);
          setLoading(false);
          return;
        }
        if (sellPrice < priceListItem.brCost) {
          AppHelper.showMessageWarningBar(`Sell Price cannot be less than B&R Cost.`);
          setLoading(false);
          return;
        }
        // get calculations
        let { ts: totalSale, tc: totalCost, p: profit, m: margin } = calculateTotals(quantity, sellPrice, priceListItem.brCost * (props.isAnnual ? 12 : 1));

        // create payload
        let pl: SalesItem = { ...props.curSalesItem };

        pl.description = priceListItem.description;
        pl.id = priceListItem.id;
        pl.licAgrType = priceListItem.licAgrType;
        pl.productId = priceListItem.productId;
        pl.source = priceListItem.source;
        pl.brCost = priceListItem.brCost;
        pl.msrp = priceListItem.msrp;
        pl.billingPlan = priceListItem.billingPlan;
        pl.termDuration = priceListItem.termDuration;

        pl.quantity = quantity;
        pl.sellPrice = sellPrice;
        pl.margin = margin;
        pl.profit = profit;
        pl.totalCost = totalCost;
        pl.totalSale = totalSale;

        if (pl.itemId === '') {
          // create new sales item
          pl.itemId = GenUtil.generateGuid();
          props.handleSubmitDrawer(pl, true);
        }
        else {
          // update existing sales item
          props.handleSubmitDrawer(pl, false);
        }

        //setLoading(false);
      })();
    }, 100);
  }

  function renderPriceLevels() {

    let col1: string[] = [];

    props.priceListItemsCol.items.forEach((o) => {
      if (col1.findIndex((o2) => {
        return o2 === o.licAgrType;
      }) < 0) {
        col1.push(o.licAgrType);
      }
    });

    col1 = _.sortBy(col1);

    return col1.map((o, i) => {
      return (
        <Option key={i} value={`${o}`}>{`${o}`}</Option>
      );
    });
  }

  function handlePriceLevelsChange(val: string) {
    setSelPriceLevel(val);

    form.setFieldsValue({
      priceList: undefined,
      description: '',
      productId: '',
      brCost: '',
      msrp: '',
      quantity: '',
      sellPrice: '',
      termDuration: '',
      billingPlan: '',
      source: '',
    });

    setTotalSale(0);
    setTotalCost(0);
    setProfit(0);
    setMargin(0);
  }

  function renderPriceListItems() {

    let col1: PriceListItem[] = [];
    col1 = props.priceListItemsCol.items.filter((o) => {
      return o.licAgrType === selPriceLevel;
    });

    col1 = _.sortBy(col1, ['description']);
    return col1.map((o, i) => {
      return (
        <Option key={i} value={o.id}>{`${o.description} - [${o.billingPlan} ${o.termDuration} ${o.source}]`}</Option>
      );
    });
  }

  function handlePriceListItemsChange(val: string) {

    setTotalSale(0);
    setTotalCost(0);
    setProfit(0);
    setMargin(0);

    if (GenUtil.safeTrim(val) === '') {
      form.setFieldsValue({
        description: '',
        productId: '',
        brCost: '',
        msrp: '',
        quantity: '',
        sellPrice: '',
        termDuration: '',
        billingPlan: '',
        source: ''
      });
    }
    else {
      let item = props.priceListItemsCol.items.filter(o => o.id === parseInt(val));

      setSelPriceListItemId(parseInt(val));

      form.setFieldsValue({
        description: item[0].description,
        productId: item[0].productId,
        brCost: GenUtil.numberToCurrency(item[0].brCost * (props.isAnnual ? 12 : 1), true),
        msrp: GenUtil.numberToCurrency(item[0].msrp * (props.isAnnual ? 12 : 1), true),
        quantity: '',
        sellPrice: '',
        termDuration: item[0].termDuration,
        billingPlan: item[0].billingPlan,
        source: item[0].source,
      });
    }
  }

  function handleFormTextboxChange() {

    let quantity = GenUtil.safeToNumber(form.getFieldValue('quantity'));
    let sellPrice = GenUtil.safeToNumber(form.getFieldValue('sellPrice'));
    let brCost = GenUtil.safeToNumber(form.getFieldValue('brCost'));

    let { ts, tc, p, m } = calculateTotals(quantity, sellPrice, brCost);

    setTotalSale(ts);
    setTotalCost(tc);
    setProfit(p);
    setMargin(m);
  }

  function calculateTotals(quantity: number, sellPrice: number, brCost: number) {

    if (quantity <= 0 || sellPrice <= 0) {
      return {
        ts: 0, tc: 0, p: 0, m: 0
      };
    }
    else {

      let _totalSale = quantity * sellPrice;
      let _totalCost = quantity * brCost;
      let _profit = _totalSale - _totalCost;
      let _margin = (_profit / _totalSale) * 100;

      return {
        ts: _totalSale, tc: _totalCost, p: _profit, m: _margin
      };
    }
  }

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const formTailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  function handleFormSellPriceChange(e: any) {
    setDiscount(0);
    form.setFieldsValue({
      discountPercent: ''
    });

    handleFormTextboxChange();
  }

  function handleFormDiscountChange(e: any) {
    const d = Number(e.target.value);
    if (Number.isNaN(d) || d < 0 || d > 100) {
      setDiscount(0);

    } else {
      setDiscount(d);
    }
  }

  function applyDiscount() {
    if (!form.getFieldValue('discountPercent')) {
      return;
    }

    let msrp = GenUtil.safeToNumber(form.getFieldValue('msrp'));
    const discountValue = discount > 0 ?
      (discount / 100) * msrp :
      0

    form.setFieldsValue({
      sellPrice: (msrp - discountValue).toFixed(2)
    });

    handleFormTextboxChange();

    setDiscount(0);
    form.setFieldsValue({
      discountPercent: ''
    });
  }

  return (
    <>

      <div className='wbs25'>
        {priceItemWarning &&
          <Alert message={priceItemWarning} type="warning" style={{ marginBottom: '12px' }} showIcon />
        }

        <Form
          form={form}
          style={{ width: '75%' }}
          {...formLayout}
          layout="horizontal"
          onFinish={handleOnFinish}
        >

          {
            props.curSalesItem.itemId === '' && (
              <>
                <Title level={4} className='wbs25'>Choose Sales Item:</Title>

                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="Price Level"
                      name="priceLevel"
                      initialValue="CORPORATE"
                    >
                      <Select
                        style={{ width: 850 }}
                        placeholder="Choose a price level"
                        showSearch
                        allowClear
                        filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={handlePriceLevelsChange}
                      >
                        {renderPriceLevels()}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="Price List"
                      name="priceList"
                      initialValue={undefined}
                    >
                      <Select
                        style={{ width: 850 }}

                        placeholder="Choose a sales item"
                        showSearch
                        allowClear
                        filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={handlePriceListItemsChange}
                      >
                        {renderPriceListItems()}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />
              </>
            )
          }


          <Title level={4} className='wbs25'>Sale Item Info:</Title>

          <Row>
            <Col span={8}>
              <Form.Item
                label="Description"
                name="description"
              >
                <Input readOnly disabled className='w850' />
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="Billed"
                name="billingPlan"
              >
                <Input readOnly disabled className='w200' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Term"
                name="termDuration"
              >
                <Input readOnly disabled className='w200' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Source"
                name="source"
              >
                <Input readOnly disabled className='w200' />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item
                label="MSRP"
                name="msrp"
              >
                <Input readOnly disabled className='w200' />
              </Form.Item>

            </Col>
            <Col span={8}>
              <Form.Item
                label="B&amp;R Cost"
                name="brCost"
              >
                <Input readOnly disabled className='w200' />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Product ID"
                name="productId"
              >
                <Input readOnly disabled className='w200' />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Title level={4} className='wbs25'>Price &amp; Quantity:</Title>

          <Row>

            <Col span={8}>
              <Form.Item
                label="Quantity"
                name="quantity"
                initialValue={props.curSalesItem.itemId === '' ? undefined : props.curSalesItem.quantity}
                rules={[{ required: true, message: 'Please enter a value' },
                { message: 'Invalid number (integer)', pattern: /^(\d+)$/ }]}
              >
                <Input className='w200' onChange={handleFormTextboxChange} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Sell Price"
                name="sellPrice"
                initialValue={props.curSalesItem.itemId === '' ? undefined : props.curSalesItem.sellPrice}
                rules={[{ required: true, message: 'Please enter a value' },
                { message: 'Invalid US Currency', pattern: Consts.REGEX_CURRENCY }]}
              >
                <Input className='w200' onChange={handleFormSellPriceChange} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Discount (%)"
                name="discountPercent"
                initialValue={''}
                rules={[
                  {
                    message: 'Number must be between 0 and 100',
                    pattern: /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/
                  }
                ]}
              >
                <Input className='w150' onChange={handleFormDiscountChange} />
              </Form.Item>
            </Col>

            <Col span={2}>
              <Button type="primary" htmlType="button" onClick={applyDiscount} disabled={loading || discount < 0 || !selPriceListItemId} icon={<CheckOutlined />}>Apply</Button>
            </Col>

          </Row>
          <Row>

            <Col span={8}>
              <Form.Item
                label="Total Sale"
              >
                <Input value={GenUtil.numberToCurrency(totalSale, true)} readOnly disabled className='w200' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Profit"
              >
                <Input value={GenUtil.numberToCurrency(profit, true)} readOnly disabled className='w200' />
              </Form.Item>
            </Col>
          </Row>

          <Row>

            <Col span={8}>
              <Form.Item
                label="Total Cost"
              >
                <Input value={GenUtil.numberToCurrency(totalCost, true)} readOnly disabled className='w200' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Margin"
              >
                <Input value={GenUtil.numberToCurrency(margin, false) + "%"} readOnly disabled className='w200' />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <div className='wbs25' >
            <Space>
              <Button type="default" htmlType="button" onClick={props.handleCancelDrawer} disabled={loading} icon={<CloseOutlined />}>Cancel</Button>
              <Button type="primary" htmlType="submit" disabled={loading} icon={<SaveOutlined />} onClick={() => {
                form.submit();
              }}>Save</Button>
            </Space>
          </div>

        </Form>
      </div >
    </>
  );
};