/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useState, useEffect } from 'react';

// ant imports
import {
  Input,
  Space,
  Button,
  Table
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import * as Consts from "../../Library/Consts";


export interface IWrappedTableProps {
  dataSource: any[];
  columns: any[];
  loading?: boolean;
  handleSelectionChange?: (a: any, b: any) => void;
  rowKey?: string;
  size?: string;
  skipRowSelection?: boolean;
  width?: number;
  useClientOnlyPager?: any;
  pageSize: number;
}

export const WrappedTable: React.FunctionComponent<IWrappedTableProps> = (props: IWrappedTableProps) => {

  useEffect(() => {
    // Consts.DEBUG_LOG_DIDMOUNT && console.log('[WrappedTable] mounted', `data=${props.dataSource.length}/cols=${props.columns.length}`);
  }, [props.dataSource, props.columns]);


  const [searchText, setSearchText] = useState('');

  const [searchedColumn, setSearchedColumn] = useState('');

  const [pageSize, setPageSize] = useState<number>(props.pageSize);


  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Enter filter...`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
  });


  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };


  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };


  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (props.handleSelectionChange)
        props.handleSelectionChange(selectedRowKeys, selectedRows);
    }
  };


  function generateFilteredCols() {

    let cols: any[] = [];

    props.columns.forEach(x => {

      let t = { ...x };

      if (typeof x['customFilter'] !== 'undefined') {
        t = { ...t, ...getColumnSearchProps(x['customFilter'].toString()) };
      }

      if (typeof x['sorter'] !== 'undefined') {
        t = { ...t, showSorterTooltip: false };
      }

      cols.push(t);
    });

    return cols;
  }


  let sizeProp: any = { size: 'small' };
  if (props.size) {
    sizeProp = { size: props.size };
  }

  let rowSelectionProps: any = {
    rowSelection: {
      type: 'checkbox',
      ...rowSelection,
    }
  }

  if (props.skipRowSelection) rowSelectionProps = {};

  let pager: any = false;
  if (props.useClientOnlyPager) {
    pager = {
      pageSize: pageSize,
      total: props.dataSource.length,
      showTotal: () => `Total ${props.dataSource.length} records`,
      size: 'default',
      showSizeChanger: false
    };
  }

  return (
    <>
      <Table
        {...sizeProp}
        rowKey={props.rowKey ? props.rowKey : 'id'}
        dataSource={props.dataSource}
        columns={generateFilteredCols()}
        pagination={pager}
        loading={props.loading}
        style={{ width: props.width ? props.width : 'auto' }}
        {...rowSelectionProps}
      />
    </>
  );
};