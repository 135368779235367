/* eslint-disable @typescript-eslint/no-unused-vars */

import * as Consts from './Consts';
import GenUtil from './GenUtil';

export interface SvcRespBase {
  isErr: boolean;
  isWarn: boolean;
  msg: string;
  data?: any;
}


export interface AuthResp {
  isAuth: boolean;
  role: string; // values: user/admin
  reason: string;
}


export interface CosmosDbEntity {
  _attachments?: string;
  _etag?: string;
  _rid?: string;
  _self?: string;
  _ts?: number;
}


export interface ClientPrincipal {
  /*
  sample resp from '.auth/me':
    {"clientPrincipal":{"userId":"9de93b8b6634dfcb5dee515b38f3d469","userRoles":["anonymous","authenticated","admin"],"identityProvider":"aad","userDetails":"bentest"}}
  */
  identityProvider: string;
  userDetails: string;
  userId: string;
  userRoles: string[];
}


//=========================================================


export interface CRPriceListItem { // idx starts at 0
  id: number;
  offerDisplayName: string; // idx=3
  // offerId: string; // idx=4, NOT NEEDED!
  licenseAgreementType: string; // idx=5
  listPrice: number; // idx=9
  erpPrice: number; // idx=10
  material: string; // idx=11
}


export interface SSCSPPriceListItem { // idx starts at 0
  id: number;
  productId: string; // idx=1
  skuTitle: string; // idx=3
  segment: string;  // idx=4 // should always 'Commercial', convert to 'Corporate'
  listPrice: number; // idx=6
  msrp: number; // idx=7
  // currency: string; // idx=5 // used for import filtering only!
  // region: string; // idx=9 // used for import filtering only!
}


export interface PriceListItem {
  id: number; // autoint, my custom field, counter when importing from excel
  description: string; // offerdispname/skutitle
  licAgrType: string; // multi values
  brCost: number; // listprice/listprice
  msrp: number; // erp/msrp
  productId: string; // material/productid
  termDuration: string; // length of time quote is for; P1M, P1Y, P3Y
  billingPlan: string; // billing cycle: OneTime, Annual, Triennial, Monthly
  source: string; // my custom field, letting me know what file it came from, never used in application, just found in data to help explain where it came from
}


export interface PriceListItemCollection extends CosmosDbEntity {
  id: string;
  items: PriceListItem[];
}


//=========================================================


export interface BRQuote extends CosmosDbEntity {
  id: string; // auto, guid
  isDeleted: boolean;
  quoteNumber: string; // auto, update when creating, should be "2021-0001", curFY+0000, count of quotes in current year padded with leading zeros
  curYear: number; // auto, clientside
  createdDatetime: Date; // auto, clientside
  createdBy: string; // auto, clientside
  modifiedDatetime: Date; // auto, clientside
  modifiedBy: string; // auto, clientside

  closeDate: Date | null; // date, visible when when status is "CLOSED", then required, NEW 8/6/21
  renewalDate: Date | null; // date, visible when when status is "CLOSED", then optional, NEW 8/6/21

  status: string; // string, required, NEW 7/25/21

  brContactName: string; // user entry
  quoteDate: Date; // user entry
  quoteExpires: Date; // user entry
  terms: string; // user entry
  subject: string; // user entry

  isAnnual: boolean; // bool, required, NEW 7/25/21
  showTermsFooter: boolean; // bool, required, NEW 8/26/21, default=true

  brClient: BRClient;

  salesItems: SalesItem[];

  taxRate: number; // 4 decimal places allowed
  taxLabel: string;

  // lets save the tots too, but recalculate on salesitems changes and render
  totalSale: number; // currency, 2 decimal places
  totalCost: number; // currency, 2 decimal places
  totalProfit: number; // currency, 2 decimal places
  totalMargin: number; // percentage, 2 decimal places
  totalQuantity: number; // integer
}


export interface BRClient {
  busName1: string;
  busName2: string; // optional
  street1: string;
  street2: string; // optional
  city: string;
  usState: string; // select
  zip: string;
  phone: string; // optional
  email: string; // optional
  contactName1: string;
  contactName2: string; // optional
}


export interface SalesItem extends PriceListItem {
  itemId: string; // string, guid, different than PriceListItem.id

  quantity: number; // integer, 0 decimal places
  sellPrice: number; // currency, 2 decimal places

  totalSale: number;
  totalCost: number;
  profit: number;
  margin: number;
}


export function createBRQuoteObject(): BRQuote {
  return {

    id: GenUtil.generateGuid(),
    isDeleted: false,
    quoteNumber: '0000', // use this value to determine if new or existing Quote
    curYear: (new Date().getFullYear()),

    createdDatetime: new Date(),
    modifiedDatetime: new Date(),
    createdBy: 'TBD', // cur logged in username (userDetail)
    modifiedBy: 'TBD', // cur logged in username (userDetail)

    closeDate: null,
    renewalDate: null,

    status: Consts.ENUM_STATUS.DRAFT,

    brContactName: '',
    quoteDate: new Date(), // user will input
    quoteExpires: new Date(), // user will input
    terms: '',
    subject: '',

    isAnnual: false,
    showTermsFooter: false,

    brClient: {
      city: '',
      contactName1: '',
      contactName2: '',
      email: '',
      busName1: '',
      busName2: '',
      phone: '',
      usState: '',
      street1: '',
      street2: '',
      zip: ''
    },

    salesItems: [],

    taxLabel: '',
    taxRate: 0,

    totalQuantity: 0,
    totalCost: 0,
    totalSale: 0,
    totalMargin: 0,
    totalProfit: 0
  };
}


export function createSalesItemObject(): SalesItem {
  return {
    itemId: '', // use this value to determine if new or existing SalesItem, will become guid

    quantity: 0,
    sellPrice: 0,

    margin: 0,
    profit: 0,
    totalCost: 0,
    totalSale: 0,

    id: 0, // this is the pricelistitemid from import, int
    description: '',
    licAgrType: '',
    brCost: 0,
    msrp: 0,
    productId: '',
    source: '',
    billingPlan: '',
    termDuration: ''
  };
}


//=========================================================
