/* eslint-disable @typescript-eslint/no-unused-vars */
import GenUtil from './GenUtil';
import * as Consts from './Consts';
import * as Model from './Models';
import axios from 'axios';

/*
Axios treats non 200 responses as exceptions in a trycatch
This version expects all responses from custom api to be wrapped in SvcRespBase
So always expecting a 200 response from web api
Then detect the actual issue in caller code in components (isErr, isWarn, msg, data)
Unhandled exceptions from web api or other http >200 errors (400, 500) will be caught, and wrapped, and returned to caller
*/

export default class WebApiHelper {

  public static get1 = async (relUrl: string): Promise<Model.SvcRespBase> => {

    try {
      //console.log(resp); // the wrapped axios response, object members: data={}, status=200, statusText=OK, header={}, config={}, request={}

      const resp = await axios.get(relUrl);

      return resp.data as Model.SvcRespBase;

    } catch (error) {

      return WebApiHelper.handleAxiosError(error);
    }
  }

  public static post1 = async (relUrl: string, data: any): Promise<Model.SvcRespBase> => {

    try {

      let d = data;
      if (d == null) d = (new Date());

      const resp = await axios.post(relUrl, d);

      return resp.data as Model.SvcRespBase;

    } catch (error) {

      return WebApiHelper.handleAxiosError(error);
    }
  }

  private static handleAxiosError = (error: any): Model.SvcRespBase => {

    if (!!error) {
      console.error(error);
      if (!!error.response) {
        console.error(error.response);
        if (!!error.response.data) {
          console.error(error.response.data);
        }
      }
    }

    if (!!error && !!error.response && !!error.response.data) {
      // request was made and the server responded with a status code that falls out of the range of 2xx
      // this is likely when 500 errors occur (unhandled exceptions) or auth (401) related issues or not found (404), basically uncontrolled error from service or server

      let err: any = error.response.data;

      if (typeof err === 'object')
        err = JSON.stringify(err);

      return {
        isErr: true,
        isWarn: false,
        msg: err, // NOTE: this could cause issues if ".data" is a complex object and not a string message, for testing i can make a POST request to a web api that has an invalid signature, like POSTING an object, but the api params is int
        data: null
      } as Model.SvcRespBase;

    }
    else {
      // unknown error or no proper response from server (ex. IIS or iis-dev is down)

      return {
        isErr: true,
        isWarn: false,
        msg: Consts.Messages.ErrorCheckConsole,
        data: null
      } as Model.SvcRespBase;
    }
  }

}
