/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Descriptions,
  Input,
  Layout,
  Popconfirm,
  Radio,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import _ from 'lodash';

import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
} from '@ant-design/icons';

import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';
import {
  BRQuote,
  ClientPrincipal,
  SalesItem,
} from '../../Library/Models';
import WebApiHelper from '../../Library/WebApiHelper';
import { WrappedTable } from '../Helpers/WrappedTable';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;


export interface IQuoteSummaryProps {
  curUser: ClientPrincipal;
  curQuote: BRQuote;
  handleCancelDrawer: () => void;
  handleDeleteDrawer: () => void;
  handleEditDrawer: () => void;
  handlePrintDrawer: () => void;
  handleCloneDrawer: (o: BRQuote) => void;
  handleUpdateStatusDrawer: (o: BRQuote) => void;
}

export const QuoteSummary: React.FunctionComponent<IQuoteSummaryProps> = (props: React.PropsWithChildren<IQuoteSummaryProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[QuoteSummary] mounted!');
  }, []);


  const [loading, setLoading] = useState(false);
  const [selStatus, setSelStatus] = useState(typeof props.curQuote.status === 'undefined' || GenUtil.isNull(props.curQuote.status) ? Consts.ENUM_STATUS.DRAFT : props.curQuote.status);
  const [showStatusChanger, setShowStatusChanger] = useState(false);

  function handleStatusChange(e: any) {

    let curStatus = e.target.value;

    setSelStatus(curStatus);

    if (loading) return;

    setLoading(true);

    setTimeout(() => {

      (async () => {

        // create payload
        let pl: BRQuote = { ...props.curQuote };

        if (curStatus !== Consts.ENUM_STATUS.CLOSED) {
          pl.closeDate = null;
          pl.renewalDate = null;
        }

        pl.status = curStatus;
        pl.modifiedBy = props.curUser.userDetails;
        pl.modifiedDatetime = new Date();


        // update quote in cosmosdb
        let updateResp = await WebApiHelper.post1(`/api/web-svc?action=COSMOSDBUPDATE&c=${Consts.CONTAINER_QUOTES}`, pl);

        if (updateResp.isErr) {
          AppHelper.showMessageErrorBar('ERROR: ' + updateResp.msg);
          setLoading(false);
          return;
        }
        else {

          setLoading(false);

          props.handleUpdateStatusDrawer(updateResp.data);

          setShowStatusChanger(false);

          if (curStatus === Consts.ENUM_STATUS.CLOSED)
            AppHelper.showMessageWarningBar('Status has been changed to Closed, please edit the Quote and enter a Close Date and Renewal Date.');

          return;
        }

      })();
    }, 100);
  }


  function handleCloneQuote() {
    // copy current quote, clone to new quote, redirect to summary page of that new quote (adding to grid too)

    if (loading) return;

    setLoading(true);

    setTimeout(() => {

      (async () => {

        const pl = { ...props.curQuote };

        pl.id = GenUtil.generateGuid();
        pl.isDeleted = false;
        // quoteNumber: see below...
        pl.curYear = (new Date().getFullYear());

        pl.createdDatetime = new Date();
        pl.modifiedDatetime = new Date();
        pl.createdBy = props.curUser.userDetails;
        pl.modifiedBy = props.curUser.userDetails;

        // build NEW quoteNumber from cosmosdb query
        let countResp = await WebApiHelper.get1(`/api/web-svc?action=${'COSMOSDBQUERY'}&c=${encodeURIComponent(Consts.CONTAINER_QUOTES)}&q=${encodeURIComponent(`SELECT COUNT(1) as counter FROM c WHERE c.curYear=${(new Date()).getFullYear()}`)}`);

        if (countResp.isErr) {
          AppHelper.showMessageErrorBar('ERROR: ' + countResp.msg);
          setLoading(false);
          return;
        }

        let counter: number = countResp.data[0].counter + 1; // increment by 1

        // update quote number in object
        pl.quoteNumber = `${new Date().getFullYear()}-${GenUtil.padLeadingZeros(counter + '', 4)}`;

        // save new quote in cosmosdb
        let saveResp = await WebApiHelper.post1(`/api/web-svc?action=COSMOSDBINSERT&c=${Consts.CONTAINER_QUOTES}`, pl);

        if (saveResp.isErr) {
          AppHelper.showMessageErrorBar('ERROR: ' + saveResp.msg);
          setLoading(false);
          return;
        }
        else {

          setLoading(false);

          AppHelper.showMessageSuccessBar('Saved!');

          props.handleCloneDrawer(saveResp.data);

          return;
        }

      })();
    }, 100);
  }


  function handleDeleteQuote() {

    if (loading) return;

    setLoading(true);

    setTimeout(() => {

      (async () => {

        let pl = { ...props.curQuote };
        pl.isDeleted = true; // mark it as deleted, send to web svc to update it

        let updateResp = await WebApiHelper.post1(`/api/web-svc?action=COSMOSDBUPDATE&c=${Consts.CONTAINER_QUOTES}`, pl);

        if (updateResp.isErr) {
          AppHelper.showMessageErrorBar('ERROR: ' + updateResp.msg);
          setLoading(false);
          return;
        }
        else {

          //setLoading(false);

          AppHelper.showMessageSuccessBar('Deleted!');

          props.handleDeleteDrawer();

          return;
        }

      })();
    }, 100);
  }


  function updateSalesItemsWithTotals() {

    if (props.curQuote.salesItems.length <= 1) {
      return props.curQuote.salesItems;
    }

    let col = [...props.curQuote.salesItems];

    let { tot_qty, tot_tc, tot_ts, tot_pr, tot_ma } = AppHelper.calcSalesItemsTotals(props.curQuote.salesItems);

    col = [...col, {
      brCost: -999,
      description: 'Totals',
      id: -998,
      itemId: '-998',
      licAgrType: '',
      margin: tot_ma,
      msrp: -999,
      productId: '',
      profit: tot_pr,
      quantity: tot_qty,
      sellPrice: -999,
      source: '',
      totalCost: tot_tc,
      totalSale: tot_ts,
      billingPlan: '',
      termDuration: ''
    }];

    if (!props.curQuote.isAnnual) {

      col = [...col, {
        brCost: -999,
        description: 'Annualized',
        id: -999,
        itemId: '-999',
        licAgrType: '',
        margin: tot_ma,
        msrp: -999,
        productId: '',
        profit: tot_pr * 12,
        quantity: tot_qty,
        sellPrice: -999,
        source: '',
        totalCost: tot_tc * 12,
        totalSale: tot_ts * 12,
        billingPlan: '',
        termDuration: ''
      }];
    }

    return col;
  }


  const salesItemsCols: any[] = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? '' : o.description
      )
    },
    {
      title: 'Billed',
      dataIndex: 'billingPlan',
      key: 'billingPlan'
    },
    {
      title: 'Term',
      dataIndex: 'termDuration',
      key: 'termDuration'
    },
    {
      title: 'MSRP',
      dataIndex: 'msrp',
      align: 'right',
      key: 'msrp',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? '' : GenUtil.numberToCurrency(o.msrp * (props.curQuote.isAnnual ? 12 : 1), true)
      )
    },
    {
      title: 'B&R Cost',
      dataIndex: 'brCost',
      align: 'right',
      key: 'brCost',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? '' : GenUtil.numberToCurrency(o.brCost * (props.curQuote.isAnnual ? 12 : 1), true)
      )
    },
    {
      title: 'Sell Price',
      dataIndex: 'sellPrice',
      align: 'right',
      key: 'sellPrice',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='quote_grid_totals'>{o.description}:</span> :
          GenUtil.numberToCurrency(o.sellPrice, true)
      )
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'right',
      key: 'quantity',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{o.quantity}</span>
          : <span>{new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(o.quantity)}</span>
      )
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      align: 'right',
      key: 'totalCost',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.totalCost, true)}</span>
          : <span>{GenUtil.numberToCurrency(o.totalCost, true)}</span>
      )
    },
    {
      title: 'Total Sale',
      dataIndex: 'totalSale',
      align: 'right',
      key: 'totalSale',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.totalSale, true)}</span>
          : <span>{GenUtil.numberToCurrency(o.totalSale, true)}</span>
      )
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      align: 'right',
      key: 'profit',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.profit, true)}</span>
          : <span>{GenUtil.numberToCurrency(o.profit, true)}</span>
      )
    },
    {
      title: 'Margin',
      dataIndex: 'margin',
      align: 'right',
      key: 'margin',
      render: (t: string, o: SalesItem) => (
        o.msrp === -999 ? <span className='bar-bold'>{GenUtil.numberToCurrency(o.margin, false) + '%'}</span>
          : <span>{GenUtil.numberToCurrency(o.margin, false) + '%'}</span>
      )
    }
  ];


  const statusOptions = [
    { label: Consts.ENUM_STATUS.DRAFT, value: Consts.ENUM_STATUS.DRAFT },
    { label: Consts.ENUM_STATUS.SUBMITTED, value: Consts.ENUM_STATUS.SUBMITTED },
    { label: Consts.ENUM_STATUS.CLOSED, value: Consts.ENUM_STATUS.CLOSED },
    { label: Consts.ENUM_STATUS.REJECTED, value: Consts.ENUM_STATUS.REJECTED },
    { label: Consts.ENUM_STATUS.DEAD, value: Consts.ENUM_STATUS.DEAD }
  ];


  // ###############################################################
  //console.log('QuoteSummary rendered!');

  return (
    <>

      <div className='wbs25'>

        <div className='wbs25 div-quote-summary'>
          <Descriptions title='Quote Summary:' layout='horizontal' bordered size='small' column={2}>

            {/* <Descriptions.Item label='ID:'>{props.curQuote.id}</Descriptions.Item> */}
            <Descriptions.Item label='Quote Number:'>{props.curQuote.quoteNumber}</Descriptions.Item>

            <Descriptions.Item label='Status:'>
              <Space>

                <Tag color={AppHelper.getStatusTagColor(props.curQuote.status)}>{props.curQuote.status}</Tag>

                {
                  !showStatusChanger && (
                    <Button type='link' size='small' onClick={() => { setShowStatusChanger(true); }}>Change Status</Button>
                  )
                }

                {
                  showStatusChanger && (
                    <Radio.Group
                      disabled={loading}
                      options={statusOptions}
                      onChange={handleStatusChange}
                      value={selStatus}
                      optionType='button'
                      buttonStyle='solid'
                    />
                  )
                }

              </Space>

            </Descriptions.Item>

            <Descriptions.Item label='Subject:'>{props.curQuote.subject}</Descriptions.Item>
            <Descriptions.Item label='B&amp;R Contact Name:'>{props.curQuote.brContactName}</Descriptions.Item>
            <Descriptions.Item label='Contract Terms:'>{props.curQuote.terms}</Descriptions.Item>
            <Descriptions.Item label='Quote Date:'>{GenUtil.dateOnlyToString(props.curQuote.quoteDate)}</Descriptions.Item>
            <Descriptions.Item label='Expires Date:'>{GenUtil.dateOnlyToString(props.curQuote.quoteExpires)}</Descriptions.Item>
            <Descriptions.Item label='Show Terms Footer:' span={2}>{props.curQuote.showTermsFooter.toString()}</Descriptions.Item>

            {
              props.curQuote.status === Consts.ENUM_STATUS.CLOSED && (
                <>
                  <Descriptions.Item label='Close Date:'>{GenUtil.dateOnlyToString(props.curQuote.closeDate)}</Descriptions.Item>
                  <Descriptions.Item label='Renewal Date:'>{GenUtil.dateOnlyToString(props.curQuote.renewalDate)}</Descriptions.Item>
                </>
              )
            }

          </Descriptions>
        </div>


        <div className='wbs25 div-quote-summary'>
          <Descriptions title={undefined} layout='horizontal' bordered size='small' column={2}>

            <Descriptions.Item label='Created:'>{GenUtil.dateToString(props.curQuote.createdDatetime)}</Descriptions.Item>
            <Descriptions.Item label='Modified:'>{GenUtil.dateToString(props.curQuote.modifiedDatetime)}</Descriptions.Item>

            <Descriptions.Item label='Created By:'>{props.curQuote.createdBy}</Descriptions.Item>
            <Descriptions.Item label='Modified By:'>{props.curQuote.modifiedBy}</Descriptions.Item>

          </Descriptions>
        </div>


        <div className='wbs25 div-quote-summary'>
          <Descriptions title='Client Information:' layout='horizontal' bordered size='small' column={2}>

            <Descriptions.Item label='Business/Org. Name:'>{props.curQuote.brClient.busName1}</Descriptions.Item>
            <Descriptions.Item label='Business/Org. Name Extra:'>{props.curQuote.brClient.busName2}</Descriptions.Item>
            <Descriptions.Item label='Street Address 1:'>{props.curQuote.brClient.street1}</Descriptions.Item>
            <Descriptions.Item label='Street Address 2:'>{props.curQuote.brClient.street2}</Descriptions.Item>
            <Descriptions.Item label='City:'>{props.curQuote.brClient.city}</Descriptions.Item>
            <Descriptions.Item label='State:'>{props.curQuote.brClient.usState}</Descriptions.Item>
            <Descriptions.Item label='Zip:' span={2}>{props.curQuote.brClient.zip}</Descriptions.Item>
            <Descriptions.Item label='Primary Phone:'>{props.curQuote.brClient.phone}</Descriptions.Item>
            <Descriptions.Item label='Primary Email:'>{props.curQuote.brClient.email}</Descriptions.Item>
            <Descriptions.Item label='Primary Contact Name:'>{props.curQuote.brClient.contactName1}</Descriptions.Item>
            <Descriptions.Item label='Secondary Contact Name:'>{props.curQuote.brClient.contactName2}</Descriptions.Item>

          </Descriptions>
        </div>


        <div className='wbs25 div-quote-summary'>
          <Descriptions title='Tax Information:' layout='horizontal' bordered size='small' column={2}>

            <Descriptions.Item label='Tax Rate:'>{props.curQuote.taxRate === 0 ? 'N/A' : props.curQuote.taxRate}</Descriptions.Item>
            <Descriptions.Item label='Tax Label:'>{GenUtil.NVL(props.curQuote.taxLabel, 'N/A')}</Descriptions.Item>

          </Descriptions>
        </div>


        <div className='wbs25'>
          <div style={{ fontSize: '20px', fontWeight: 600, paddingRight: '12px' }}>Sales Items:</div>
        </div>


        <div className='wbs25'>
          <WrappedTable
            columns={salesItemsCols}
            dataSource={updateSalesItemsWithTotals()}
            loading={loading}
            rowKey={'itemId'}
            skipRowSelection={true}
            useClientOnlyPager={false}
            size='small'
            pageSize={5000}
          ></WrappedTable>
        </div>


        <Space style={{ marginTop: 25 }}>
          <Button type='default' onClick={props.handleCancelDrawer} disabled={loading} icon={<CloseOutlined />}>Back</Button>

          <Button type='primary' onClick={props.handleEditDrawer} disabled={loading} icon={<EditOutlined />}>Edit</Button>

          <Popconfirm
            title="Are you sure?"
            onConfirm={handleCloneQuote}
            okText="Yes"
            cancelText="No"
          >
            <Button type='primary' disabled={loading} icon={<CopyOutlined />}>Clone</Button>
          </Popconfirm>

          <Popconfirm
            title="Are you sure?"
            onConfirm={handleDeleteQuote}
            okText="Yes"
            cancelText="No"
          >
            <Button type='primary' disabled={loading} icon={<DeleteOutlined />}>Delete</Button>
          </Popconfirm>

          <Button type='primary' onClick={props.handlePrintDrawer} disabled={loading} icon={<PrinterOutlined />}>Print</Button>
        </Space>


      </div>

    </>
  );
};